import { themeIcons } from "config/theme";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import VIcon from "components/custom/Icon";

import { useDispatch, useSelector } from "react-redux";
import { ApiKey, ApiUrl, PanelType, TransactionType } from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine.js";
import { showResponseMessage, bindMember, setDailyCheckinModal, setBusyLoading, setDailyCheckInExist, showMessage } from "../../redux/AppAction.js";
import { find, isEmpty } from "lodash";
import { useForm } from "react-hook-form";
import { createFormBody, createMultiPartFormBody, stringIsNullOrEmpty } from "../../util/Util.js";
import { Spinner } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { chain } from "lodash";


const DailyCheckInModal = ({member}) => {
    var _dispatch = useDispatch();
    var { t } = useTranslation();
    const dailyCheckinModal = useSelector((state) => state.appState.dailyCheckinModal);
    const companyIcons = useSelector((state) => state.appState.companyIcons);
    const companyCurrency = useSelector((state) => state.appState.companyCurrency);

    const [alertIcon, setAlertIcon] = useState("");
    const memberData = useSelector((state) => state.appState.member);
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	var { heartBeat } = useSelector((state) => state.authState);
    
    const [dailyItems, setDailyItems] = useState([]);
    const [disabledClaim , setDisabledClaim] = useState(false)
    const [accumulateDay , setAccumulateDay] = useState(0)
    const [monthMode , setMonthMode] = useState(false)
    const [monthText , setMonthText] = useState('')
    const [dcid , setDcid] = useState()
    const [timezone , setTimezone] = useState(0)
    const [claimedAmount , setClaimedAmount] = useState(0)
    const [successAlert , setSuccessAlert] = useState(false)

    const {
		handleSubmit,
		register,
		control,
		watch,
		getValues,
		errors,
		clearErrors,
		reset,
		trigger,
	} = useForm();

    useEffect(()=>{
        if(heartBeat){
            init();
        }
    },[heartBeat])
    useEffect(()=>{
        getCompanyIcon();
        checkDailyCheckInStatus();
    },[])

    const init= async ()=>{
        
        var responseJson = await ApiEngine.get(
			ApiUrl._API_GET_DAILY_CHECK_IN
		);

        if(responseJson[ApiKey._API_SUCCESS_KEY]){

            if(responseJson[ApiKey._API_DATA_KEY]){
                _dispatch(setDailyCheckInExist(true))
                setDailyItems(responseJson[ApiKey._API_DATA_KEY].dailyDetails)
                setDcid(responseJson[ApiKey._API_DATA_KEY].id)
                setTimezone(responseJson[ApiKey._API_DATA_KEY].timeZone)
                const totalDaysLeft = responseJson[ApiKey._API_DATA_KEY].dailyDetails
                .filter(item => item.claimed === 0).length;
    
                setAccumulateDay(totalDaysLeft)
    
                if(responseJson[ApiKey._API_DATA_KEY].days > 28){
                    const today = new Date();
                    const months = [
                        "January", "February", "March", "April", "May", "June",
                        "July", "August", "September", "October", "November", "December"
                    ];
                    const monthName = months[today.getMonth()];
                    const year = today.getFullYear();
                    setMonthText(`${monthName} ${year}`)
                    setMonthMode(true)
                }
                
            }else{
                _dispatch(setDailyCheckInExist(false))
            }

        }else{
            _dispatch(setDailyCheckInExist(false))
        }
    }

    async function checkDailyCheckInStatus(){
        var responseJson = await ApiEngine.get(
			ApiUrl._API_DAILY_CHECK_IN_STATUS
		);
        if(responseJson[ApiKey._API_SUCCESS_KEY]){
           _dispatch(setDailyCheckInExist(responseJson[ApiKey._API_DATA_KEY]))
        }else{
            _dispatch(setDailyCheckInExist(false))
        }


    }


    async function getCompanyIcon() {
        const alert = find(companyIcons, { iconCategory: "alert" });
        if (alert) {
          setAlertIcon(alert.iconImage);
        }
    
    
    }

    const claimDailyCheckIn = async ()=>{
        const activeItem = dailyItems.find(item => item.actived === 1);
        if(!activeItem){ 
            
            _dispatch(
                showMessage({
                    custom: true,
                    customIcon: (
                            <VIcon icon={themeIcons.exclamation} className="vicon warning" />
                        ),
                    content: t("CLAIMED_TODAY"),
                    onConfirm: () => {
                        // console.log("hi");
                    },
                })
            );
            return false
        }

        let params = {
			transactionTypeId: TransactionType._DAILY_CHECK_IN,
	
			panel: PanelType._MEMBERSITE,
            dcdid: activeItem.id,
            dcid: dcid
			
		};

        _dispatch(setBusyLoading(true))
        setDisabledClaim(true)

        let responseJson = await ApiEngine.post(
			ApiUrl._API_CLAIM_DAILY_CHECK_IN,
			createMultiPartFormBody(params)
		);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      
            setClaimedAmount(responseJson[ApiKey._API_DATA_KEY].amount)
            setSuccessAlert(true)

          

           await init()
        }else{
            _dispatch(showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY]))
        }

        _dispatch(setBusyLoading(false))
        setDisabledClaim(false)

    }



    const closeBtn = (
		<div
			className="close"
			onClick={() => _dispatch(setDailyCheckinModal(false))}
		>
			<VIcon icon={themeIcons.close} className="vicon" />
		</div>
	  );

      const testDailyfunction = async ()=>{  
        const dailyList = dailyItems;


        const claimedItem = chain(dailyList)
        .orderBy(['days'], ['desc']) // Sort by 'days' in descending order
        .find(item => item.claimed === 1) // Find the first item where claimed is 1
        .value(); // Retrieve the final result



        let params = {
			
            id: claimedItem.id,
            mainId: dcid
			
		};
// return false
        _dispatch(setBusyLoading(true))

        
        let responseJson = await ApiEngine.post(
			ApiUrl._API_TEST_DAILY_CHECK_IN,
			createMultiPartFormBody(params)
		);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      
       
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]))
        

            init();
        }else{
            _dispatch(showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY]))
            init();
        }

        _dispatch(setBusyLoading(false))

        
    }

    return (
        <>
        {
            successAlert && (
                <div className="daily-sweetalert t3-sweet-alert-container">
                    <SweetAlert
                    custom
                    confirmBtnBsStyle="primary"
                    customIcon={<VIcon icon={themeIcons.dailySuccess} className="vicon daily-success" /> }
                    title={t("CONGRATULATIONS")}
                    onConfirm={() => {
                        setSuccessAlert(false);
                      }}

                    >
                    {t("SUCCESS_EARN", { credit: claimedAmount})}
                    </SweetAlert>
                </div>
                
            )
        }
                
            
            <Modal 
                fade={false}
                id="t3-custom-modal"
                isOpen={dailyCheckinModal}
                contentClassName={"t3-custom-modal"}

                toggle={() => {
                    _dispatch(setDailyCheckinModal(!dailyCheckinModal))
                }}
                centered
            >
                <ModalHeader close={closeBtn}>	
                {t("DAILY_BONUS_CLAIM")}
                </ModalHeader>
                <ModalBody>
                    
                    <div className="t3-custom-modal-container">
                        <div className="custom-body">
                            <div className="daily-checkin-announce">
                            <VIcon icon={themeIcons.refreshTime} className="vicon refresh-time"/> <div dangerouslySetInnerHTML={{ __html: t("REFRESH_TIME", { timezone: timezone }) }} />
                            </div>
                            <div className="daily-checkin-remaining">
                                {!monthMode && (<div dangerouslySetInnerHTML={{ __html: t("ACCUMULATED_DAY", { day: accumulateDay }) }} />)}

                                {/* You have accumulated <span>2 days</span> check-in. */}
                            </div>
                    
                            {/* <form onSubmit={handleSubmit(onSubmit)}> */}
                                <div className="t3-lr-form">
                                    {monthMode && (<div className="daily-month">{monthText}</div>)}
                                <div className="daily-checkin-row">
                                    {
                                        dailyItems.map((item, index) =>{
                                            let classDaily = ''
                                            if(item.claimed){
                                                classDaily = 'claimed' 
                                            }else if(item.actived && !item.claimed){
                                                classDaily = 'active' 
                                            }
                                        
                                            return (
                                                <div className={`daily-checkin-item ${classDaily}`} key={index}>

                                                    {classDaily == 'claimed' && (<div className="daily-tick"><VIcon icon={themeIcons.dailyTick} className="vicon" /></div>)}
                                                    <div className="daily-checkin-label" dangerouslySetInnerHTML={{ __html: t("DAY_HTML", { day: item.days }) }} />

                                                    <VIcon icon={themeIcons.iconReward} className="vicon daily" />
                                                 
                                                    <div className="daily-checkin-amount">{`${companyCurrency} ${item.amount}`}</div>

                                                </div>
                                            )
                                        })

                                    
                                    }
                                
                                </div>


                                    <div className="mt-4">
                                        <button type="button" className="w-100 t3-custom-light-btn" disabled={disabledClaim} onClick={()=>{claimDailyCheckIn()}}>
                                        {isSubmitLoading ? (
                                            <Spinner
                                                className="dynamic-table-spinner"
                                                animation="border"
                                                role="status"
                                                size="sm"
                                                style={{
                                                verticalAlign: "middle",
                                                width: "1.5rem",
                                                height: "1.5rem",
                                                marginLeft: 3,
                                                }}
                                            ></Spinner>
                                            ) : (
                                            t("CHECK_IN_NOW")
                                        )}
                                        </button>
                                        <button type="button" className="w-100 mt-3 t3-btn-link" onClick={()=>{_dispatch(setDailyCheckinModal(false))}}>
                                    
                                            {t("BACK_TO_HOME")}
                                        
                                        </button>
                                        {/* delete later */}
                                        <button type="button" className="w-100 mt-3 t3-btn-link" onClick={()=>{testDailyfunction()}}>
                                    
                                            {t("Test Daily")}
                                        
                                        </button>

                              
                                    </div>

                                </div>

                            {/* </form> */}
                            

                        </div>
                        
                    
                    </div>
                </ModalBody>
            </Modal>
        </>
  

    )
};

export default DailyCheckInModal;